export const getCssVariable = (key) => {
  const computedStyle = getComputedStyle(document.documentElement);
  return computedStyle.getPropertyValue(key).trim();
};

export const isLandscape = () => window.innerWidth >= window.innerHeight;

export const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 4);
  }
};

export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  ((p) => p.toString() === '[object SafariRemoteNotification]')(
    !window['safari'] ||
      (typeof safari !== 'undefined' && safari.pushNotification)
  );

export const isIOS = () =>
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const isIE = /*@cc_on!@*/ false || !!document.documentMode;

export const unwrapNoscript = (error) => {
  document.documentElement.classList.add('no-js');
  document.querySelector('div.loading-screen').classList.add('hidden');
  const unwrap = (wrapper) => {
    const docFrag = document.createDocumentFragment();
    while (wrapper.firstChild) {
      const span = document.createElement('span');
      span.innerHTML = wrapper.removeChild(wrapper.firstChild).textContent;
      docFrag.appendChild(span);
    }
    wrapper.parentNode.replaceChild(docFrag, wrapper);
  };
  const noscript = document.querySelectorAll('noscript');
  for (let i = 0; i < noscript.length; i++) {
    unwrap(noscript[i]);
  }
  window.scrollTo(0, 0);
  if (error) {
    document.querySelector('code.warning').innerHTML =
      'Apologies - something went wrong, please reload the page!';
  }
};

export const getMicrocopySlots = () => {
  const slots = document.querySelectorAll('slot.microcopy');
  const results = [];
  for (let i = 0; i < slots.length; i++) {
    results.push(slots[i]);
  }
  return results;
};

export const onError = (error) => {
  console.error(error);
  unwrapNoscript(error);
};

export const getLanguageCode = ({ code }) => code.split('-')[0];

export const useDarkMode = () => {
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    document.documentElement.classList.add('dark-mode');
  }
};

export const setDarkModeMessage = () => {
  const inverterButton = document.querySelector('button.inverter');
  const isDarkMode = document.documentElement.classList.contains('dark-mode');
  const message = `Switch to ${isDarkMode ? 'light' : 'dark'} mode`;
  inverterButton.setAttribute('aria-label', message);
  inverterButton.setAttribute('title', message);
};
