import anime from 'animejs/lib/anime.es.js';
import ScrollMagic from 'scrollmagic';
import { getCssVariable, isIOS, isLandscape } from './utils';

let controller;

const onProgress = (timeline) => (args) => {
  const { progress } = args;
  timeline.seek(progress * timeline.duration);
};

const initLogo = () => {
  const endFontSize = isLandscape() ? window.innerWidth : window.innerHeight;

  const timeline = anime.timeline({ autoplay: false, duration: 10000 });

  timeline
    .add(
      {
        targets: 'svg text#main',
        fontSize: `${endFontSize}px`,
        easing: 'linear',
      },
      1
    )
    .add(
      {
        targets: 'svg tspan#land',
        fontSize: `${endFontSize}px`,
        dx: `-${endFontSize / 7.5}px`,
        dy: 0,
        easing: 'linear',
      },
      1
    )
    .add(
      {
        targets: 'svg tspan#software',
        fontSize: `${endFontSize / 15}px`,
        dx: '0px',
        dy: '0px',
        easing: 'linear',
      },
      1
    );

  const scene = new ScrollMagic.Scene({
    offset: window.innerHeight / 2,
    triggerElement: 'section.landing', // starting scene, when reaching this element
    duration: window.innerHeight, // pin the element for a total of 50% viewport height
  });
  // ScrollMagic bug on mobile
  if (!isIOS()) {
    scene.setPin('section.landing');
  }
  scene.on('progress', onProgress(timeline)).addTo(controller);
};

const initHeadings = (sectionConfigs) => {
  sectionConfigs.forEach((_, i) => {
    const target = `.section-${i} .section-heading`;
    const timeline = anime.timeline({ autoplay: false, duration: 10000 });

    timeline
      .add(
        {
          targets: target,
          opacity: [0, 1],
          easing: 'linear',
        },
        1
      )
      .add(
        {
          targets: target,
          translateX: [10, 0],
        },
        1
      );

    new ScrollMagic.Scene({
      offset: 0,
      triggerElement: target,
      duration: window.innerHeight / 2,
    })
      .setPin(target)
      .on('progress', onProgress(timeline))
      .addTo(controller);
  });
};

const initArticles = (sectionConfigs) => {
  sectionConfigs.forEach(({ items }, sectionIdx) => {
    items.forEach((_, articleIdx) => {
      const target = `.section-${sectionIdx} .article-${articleIdx}`;

      const primary = getCssVariable('--global__color_primary');
      const secondary = getCssVariable('--global__color_secondary');
      const infoTextWidth = getCssVariable('--portfolio__width_info-text');

      const timeline = anime.timeline({ autoplay: false, duration: 15000 });

      timeline
        .add(
          {
            targets: `${target} figcaption`,
            borderColor: [primary, secondary],
          },
          2
        )
        .add(
          {
            targets: `${target} figcaption h3 div`,
            color: [primary, secondary],
          },
          2
        )
        .add(
          {
            targets: `${target} img`,
            opacity: [1, 0],
            easing: 'linear',
          },
          1
        )
        .add(
          {
            targets: `${target} figcaption`,
            backgroundColor: ['transparent', primary],
            easing: 'linear',
          },
          2
        )
        .add(
          {
            targets: `${target} .info-block svg rect`,
            strokeDashoffset: [
              (...args) => anime.setDashoffset(...args), // * (isOdd ? -1 : 1),
              1,
            ],
            easing: 'linear',
            delay: 9000,
          },
          3
        )
        .add(
          {
            targets: `${target} .info-text`,
            width: () => [0, infoTextWidth],
            easing: 'linear',
            delay: 11000,
          },
          4
        );

      new ScrollMagic.Scene({
        offset: window.innerHeight / 2,
        triggerElement: target,
        duration: window.innerHeight,
      })
        .setPin(target)
        .on('progress', onProgress(timeline))
        .addTo(controller);
    });
  });
};

const initController = () => {
  if (controller) {
    controller.destroy(true);
    controller = null;
  }
  controller = new ScrollMagic.Controller();
};

export default (config) => {
  const { sections } = config;
  initController();
  initLogo();
  sections.forEach(({ items }) => {
    initHeadings(items);
    initArticles(items);
  });
  return config;
};
