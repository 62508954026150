import * as contentful from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { INLINES } from '@contentful/rich-text-types';
export { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

const client = (() => {
  const usePreview = !!process.env.CONTENTFUL_USE_PREVIEW;
  const deliveryToken = process.env.CONTENTFUL_DELIVERY_TOKEN;
  const previewToken = process.env.CONTENTFUL_PREVIEW_TOKEN;
  const space = process.env.CONTENTFUL_SPACE_ID;
  const environment = process.env.CONTENTFUL_ENVIRONMENT_ID;

  const config = { accessToken: deliveryToken, space };
  if (usePreview) {
    config.host = 'preview.contentful.com';
    config.accessToken = previewToken;
  }
  if (environment) {
    config.environment = environment;
  }
  return contentful.createClient(config);
})();

const fetchSections = (query = {}) => {
  return client.getEntries({ content_type: 'sections', include: 10, ...query });
};

const parseSections = ({ items }) => {
  return items.map(({ fields }) => {
    if (fields.items) {
      return { ...fields, items: parseSections(fields) };
    }
    if (fields.image) {
      fields.image = fields.image.fields;
    }
    return { ...fields };
  });
};

const fetchMicrocopy = (query = {}) => {
  return client.getEntries({ content_type: 'microcopy', ...query });
};

const parseMicrocopy = ({ items }) => {
  return items.reduce(
    (acc, { fields }) => ({
      ...acc,
      [fields.key]: fields.value,
    }),
    {}
  );
};

export const parseRichText = (value) => {
  const options = {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node) => {
        const href = node.data.target.fields.file.url;
        const text = node.content[0].value;
        return `<a href="${href}">${text}</a>`;
      },
    },
  };
  return documentToHtmlString(value, options);
};

const cache = {
  microcopy: null,
  sections: null,
};

export default ({ query, locale, locales }) => {
  if (cache.microcopy && cache.sections) return Promise.resolve(cache);

  return Promise.all([
    fetchMicrocopy(query).then(parseMicrocopy),
    fetchSections(query).then(parseSections),
  ]).then(([microcopy, sections]) => {
    cache.microcopy = microcopy;
    cache.sections = sections;
    return { microcopy, sections, locale, locales };
  });
};

export const fetchLocales = () =>
  client.getLocales().then(({ items }) => items);
