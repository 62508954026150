import "vanilla-cookieconsent/dist/cookieconsent.css";
import '../styles/reset';
import '../styles/loader';
import '../styles/index';
import '../styles/no-js';
import '../styles/portfolio';
import '../styles/footer';
import '../styles/consent';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import initDom from './dom';
import initAnimations from './animations';
import fetchData, { fetchLocales } from './contentful';
import {
  scrollToTop,
  isIE,
  unwrapNoscript,
  onError,
  getLanguageCode,
  useDarkMode,
  setDarkModeMessage,
} from './utils';

const getLanguageQueryOrRedirect = () => {
  const { host, href } = window.location;
  const splitHost = host.split('.');
  const hasSubdomain = splitHost.length > 2;
  const [subdomain] = splitHost;

  return fetchLocales().then((locales) => {
    const subdomainLocale = locales.find(({ code }) =>
      code.includes(subdomain)
    );
    const [navigatorLanguage] = window.navigator.language.split('-');
    const navigatorLocale = locales.find(({ code }) =>
      code.includes(navigatorLanguage)
    );
    const defaultLocale = locales.find((locale) => locale.default);

    const locale = subdomainLocale || navigatorLocale || defaultLocale;

    if (!hasSubdomain && !host.includes('localhost')) {
      const languageCode = getLanguageCode(locale);
      const redirectTo = href.replace(
        /^(http[s]?:\/\/)/i,
        `$1${languageCode}.`
      );
      window.location.replace(redirectTo);
    }

    return {
      query: { locale: locale.code },
      locale,
      locales,
    };
  });
};

const init = () => {
  return getLanguageQueryOrRedirect()
    .then(fetchData)
    .then(initDom)
    .then(initAnimations)
    .then((config) => {
      const { locale, locales } = config;

      const reinitDom = () => {
        initDom(config);
        initAnimations(config);
      };

      setDarkModeMessage();

      document
        .querySelector('button.inverter')
        .addEventListener('click', () => {
          scrollToTop();
          document.documentElement.classList.toggle('dark-mode');
          setDarkModeMessage();
          reinitDom();
        });

      if (locales.length > 1) {
        const languageButton = document.querySelector('button.language');
        languageButton.classList.add('block');

        const currentIndex = locales.findIndex(
          ({ sys }) => locale.sys.id === sys.id
        );
        const nextIndex = (currentIndex + 1) % locales.length;
        const nextLocale = locales[nextIndex];

        const message = `Switch to ${nextLocale.name.split(' ')[0]} version`;
        languageButton.setAttribute('aria-label', message);
        languageButton.setAttribute('title', message);

        languageButton.addEventListener('click', () => {
          const redirectTo = window.location.href.replace(
            getLanguageCode(locale),
            getLanguageCode(nextLocale)
          );
          window.location.replace(redirectTo);
        });
      }

      window.addEventListener('orientationchange', reinitDom);
      window.addEventListener('resize', reinitDom);

      document.querySelector('div.loading-screen').classList.add('hidden');
      document.documentElement.classList.remove('no-js');
    });
};

try {
  useDarkMode();

  if (isIE) {
    unwrapNoscript();
  } else {
    init().catch(onError);
  }
} catch (error) {
  onError(error);
}
