import { getMicrocopySlots, getCssVariable } from './utils';
import { parseRichText, documentToPlainTextString } from './contentful';

const reverse = (str) => str.split('').reverse().join('');

const borderBox = `
<svg 
  width="100%" 
  height="100%" 
  version="1.1" 
  xmlns="http://www.w3.org/2000/svg" 
  xmlns:xlink="http://www.w3.org/1999/xlink" 
  xml:space="preserve" 
  xmlns:serif="http://www.serif.com/"
  >
  <rect 
    x="0" 
    y="0" 
    width="100%" 
    height="100%"
    fill="none"
    stroke="var(--global__color_secondary)" 
    stroke-width="var(--portfolio__stroke-width_boxes)" 
    />
</svg>`;

const getSplitHeading = (heading) => {
  const split = heading.split('.');
  let result = `<div>${heading}</div>`;
  if (split.length > 1) {
    const lengthTLD = split[1].length;
    result = reverse(heading)
      .match(new RegExp(`.{1,${lengthTLD}}`, 'g'))
      .reverse()
      .map((c) => `<div>${reverse(c)}</div>`)
      .join('');
  }
  return `<h3>${result}</h3>`;
};

const getArticles = (articleConfigs) => {
  return articleConfigs.map(({ image, heading, url, text }, i) => {
    const article = document.createElement('article');
    article.className = `article-${i}`;
    const alt = image.description || image.title || heading;

    article.innerHTML = [
      '<figure>',
      `<a href="${url || `https://${heading}`}" target="_blank">`,
      '<figcaption>',
      getSplitHeading(heading),
      '</figcaption>',
      '</a>',
      `<img src="${image.file.url}" alt="${alt}"/>`,
      '</figure>',
      '<div class="info-block">',
      borderBox,
      `<div class="info-text">${parseRichText(text)}</div>`,
      '</div>',
    ].join('');

    return article;
  });
};

const getSections = (sections) =>
  sections.map(({ heading, items }, i) => {
    const section = document.createElement('section');
    section.className = `section-${i}`;
    section.innerHTML = `<h3 class="section-heading">${heading}</h3>`;
    const articles = getArticles(items);
    articles.forEach((article) => section.appendChild(article));

    return section;
  });

const getSegments = (segments) =>
  segments.reduce((acc, { heading, items }) => {
    const h2 = document.createElement('h2');
    h2.className = 'section-heading';
    h2.innerHTML = heading;
    return [...acc, h2, ...getSections(items)];
  }, []);

const replaceMicrocopy = (microcopy) => {
  const slots = getMicrocopySlots();
  slots.forEach((slot) => {
    const value = microcopy[slot.id];
    if (!value) {
      console.error(`No microcopy found for "${slot.id}"`);
      return;
    }
    slot.innerHTML = parseRichText(value);
  });
};

const setMeta = (key, value) => {
  document.querySelector(`meta[${key}]`).setAttribute('content', value);
};

const initHead = (microcopy) => {
  const title = documentToPlainTextString(microcopy['meta.title']);
  if (title) {
    document.title = title;
    setMeta('property="og:title"', title);
  }

  const url = process.env.URL;
  if (url) {
    setMeta('property="og:url"', url);
    const el = document.querySelector('meta[property="og:image"]');
    const imageUrl = el
      .getAttribute('content')
      .replace(/^http[s]?:\/\/[^\/]*/, url);
    el.setAttribute('content', imageUrl);
  }

  const description = documentToPlainTextString(microcopy['meta.description']);
  if (description) {
    setMeta('property="og:description"', description);
    setMeta('name="description"', description);
  }
};

const initSvgDimensions = () => {
  const svg = document.querySelector('svg#logo');
  const { innerHeight: height, innerWidth: width } = window;
  svg.setAttribute('width', width);
  svg.setAttribute('height', height);

  if (window.innerWidth > 812) return;
  const halfWidth = width / 2;
  const halfHeight = height / 2;

  const text = svg.querySelector('text#main');
  text.setAttribute(
    'transform',
    `scale(0.5) translate(${halfWidth}, ${halfHeight})`
  );
};

const cache = {
  logo: null,
};

const initSvg = () => {
  const svgWrapper = document.querySelector('section.landing');
  if (!cache.logo) {
    cache.logo = svgWrapper.innerHTML;
  }
  svgWrapper.innerHTML = cache.logo;
};

export default (config) => {
  const { microcopy, sections } = config;

  replaceMicrocopy(microcopy);
  initHead(microcopy);
  initSvg();
  initSvgDimensions();

  const slotSection = document.querySelector('slot.dynamic');
  slotSection.innerHTML = '';

  const segments = getSegments(sections);
  segments.forEach((segment) => slotSection.appendChild(segment));

  return config;
};
